import React, { useEffect, useContext, useState } from "react"
import SEO from "../components/seo"
import "./index.css"

import { API, graphqlOperation } from "aws-amplify"
import { Credentials } from '@aws-amplify/core';

import * as buttonStyles from "../components/button.module.css"
import * as inputStyles from "../components/search-input.module.css"
import { LoadMaskContext } from "../provider/loadmaskprovider"

import { Notification, NotificationType } from "../components/notification";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import AnonymizedLookupItem from "../components/anonymized-lookup-item"
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { UserContext,AuthContext } from '../provider/usercontextprovider'
import { Auth } from 'aws-amplify'

const getMedicalTest = /* GraphQL */ `
query GetMedicalTest(
    $id: ID!
) {
    getAnonymizedMedicalTest(
        id: $id
    ) {
        id,
        status,
        result,
    }
}`

const AnonymizedLookupPage = () => {

  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [code, setCode] = useState("")
  const [state, setState] = useState({msg: null, success: true})
  const [userContext, setUserContext] = useContext(UserContext);
  const [authState, setAuthState] = useContext(AuthContext);

  useEffect(()=>{
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUserContext(user)
      })
      .catch(err => {
        setUserContext({username: null});
      });
    return onAuthUIStateChange(newAuthState => {
      setAuthState(newAuthState)
      if(newAuthState == 'signedout' && setUserContext){
        setUserContext({username: null});
      }
    });
  },[])

  async function lookupHandler() {
    setIsLoading(true)
    try {
      let q = graphqlOperation(getMedicalTest, {
        id: code
      })

      const credentials = await Credentials.get()

      if(!credentials.authenticated) {
        q.authMode = GRAPHQL_AUTH_MODE.AWS_IAM
      }

      const result = await API.graphql(q);
      console.log(result)
      if(result.data.getAnonymizedMedicalTest != null) {
        setState({msg: null, success: true, data: result.data.getAnonymizedMedicalTest})
      } else {
        setState({msg: "Lookup failed", success: false, data: null})
      }
    } catch (e) {
      console.log(e)
      setState({msg: "Lookup failed", success: false, data: null})
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
        <SEO title="Medical Test Lookup" />

        <div>
          <div style={{ textAlign: "center" }}>
            <h1>Lookup</h1>
          </div>
          <div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <input
                onChange={e => {
                  setCode(e.target.value)
                }}
                onKeyPress={e => {
                  if(e.key === 'Enter') {
                    lookupHandler()
                  }
                }}
                className={inputStyles.inputcss}
                type="text"
                placeholder="Test Identification"
              />
              <input
                disabled={
                  code == null || code.length == 0
                }
                className={buttonStyles.button}
                type="submit"
                value="Lookup!"
                onClick={lookupHandler}
              />
            </div>
          </div>

          <div style={{ marginTop: "2rem" }}>
            {state.data &&
              <AnonymizedLookupItem test={state.data} />
            }
            { !state.success && 
              <Notification messageType={NotificationType.FAIL}>
                <p>
                {state.msg}
                </p>
              </Notification> 
          }
          </div>

      </div>
    </div>
  )
}
export default AnonymizedLookupPage
