import { resolve } from "path";
import React from "react";
import * as enrollerStyles from "./enroller.module.css";

const responseTypeIcon = {
    Pending: 'M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z',
    Accepted: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z',
    Rejected: 'M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z',
};

const resultTypeIcon = {
    Negative: 'M5 13l4 4L19 7',
    Positive: 'M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
};

const AnonymizedLookupItem = ({ test }) => {
    const messageType = test?.status != null ? responseTypeIcon[test.status] : responseTypeIcon.Pending;
    const resultType = resultTypeIcon[test.result];
    return (
        <div className={enrollerStyles.lookupItem}>
            <div className={`${test?.status != null ? enrollerStyles[test.status] : enrollerStyles.pending}`}>
                <div className={enrollerStyles.content}>
                    <div className={enrollerStyles.icon}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={messageType} />
                        </svg>
                    </div>
                    <p>
                        Your Test {test?.status != null ? `has been ${test.status}` : "is pending"}.
                        {test?.status === "Rejected" && " Please contact your company's medical test administrator."}
                    </p>
                </div>
                {test?.status == "Accepted" &&
                    <div className={enrollerStyles.result}>
                        <svg className={enrollerStyles[test.result]} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={resultType} />
                        </svg>
                        <p>
                            Test result is {test.result}.
                            {test.result === "Negative" && " Please contact your company's medical test administrator."}
                            {test.result === "Positive" && " Please stay home and contact your company's medical test administrator."}
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default AnonymizedLookupItem;